import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
export class DynamicScriptLoaderService {
    constructor() {
        this.scripts = [];
    }
    load(script) {
        return new Observable((observer) => {
            const existingScript = this.scripts.find((s) => s.name === script.name);
            // Complete if already loaded
            if (existingScript && existingScript.loaded) {
                observer.next(existingScript);
                observer.complete();
            }
            else {
                // Add the script
                this.scripts = [...this.scripts, script];
                // Load the script
                const scriptElement = document.createElement('script');
                scriptElement.type = 'text/javascript';
                scriptElement.src = script.src;
                scriptElement.onload = () => {
                    script.loaded = true;
                    observer.next(script);
                    observer.complete();
                };
                scriptElement.onerror = (error) => {
                    observer.error('Couldn\'t load script ' + script.src);
                };
                document.getElementsByTagName('body')[0].appendChild(scriptElement);
            }
        });
    }
}
DynamicScriptLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DynamicScriptLoaderService_Factory() { return new DynamicScriptLoaderService(); }, token: DynamicScriptLoaderService, providedIn: "root" });
