import { ApiMetadataApiService } from '@xpo-ltl/sdk-apimetadata';
import * as i0 from "@angular/core";
import * as i1 from "@xpo-ltl/sdk-apimetadata";
export class LoginUserResolver {
    constructor(apiMetadataService) {
        this.apiMetadataService = apiMetadataService;
    }
    resolve(route, state) {
        return this.apiMetadataService.loggedInUser();
    }
}
LoginUserResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginUserResolver_Factory() { return new LoginUserResolver(i0.ɵɵinject(i1.ApiMetadataApiService)); }, token: LoginUserResolver, providedIn: "root" });
