import { XpoLtlAuthConfigLoaderService } from '@xpo-ltl/ngx-auth';
import { XpoAuthConfig } from '@xpo/ngx-auth';
export function authConfigFactory(configService) {
    configService.initSettings('./assets/config.json');
    return new XpoAuthConfig({
        appCode: configService.appCode,
        scopes: configService.scopes,
        isProd: configService.isProd,
        applicationRootUri: configService.appRootUri,
    });
}
const ɵ0 = { configFileUrl: './assets/config.json' };
export class AppModule {
}
export { ɵ0 };
