<xpo-shell class="app-Container" [drawerClosedByDefault]="true">
  <xpo-header-app-name class="app-Header-name">
    <span>Edge</span>
  </xpo-header-app-name>
  <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>
  <xpo-header-actions>
    <xpo-auth-user-profile></xpo-auth-user-profile>
  </xpo-header-actions>
  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let route of routes"
      [routerLink]="route.path"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ route.label }}
    </a>
  </nav>
  <router-outlet></router-outlet>
  <footer>
    <p>© 2024 XPO | Version - {{ build }}</p>
  </footer>
</xpo-shell>
<app-notification></app-notification>
