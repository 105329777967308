<xpo-card>
  <xpo-card-header>
    <xpo-card-title>Password Reset</xpo-card-title>
  </xpo-card-header>
  <xpo-card-content>
    <form [formGroup]="passwordForm">
      <div fxLayout="column">
        <mat-form-field class="form-field">
          <mat-label>Reset Password For *</mat-label>
          <mat-select formControlName="database" disableOptionCentering="true" (selectionChange)="dbChanged()">
            <mat-option value="All">All</mat-option>
            <mat-option *ngFor="let database of databaseList" [value]="database">
              {{ database }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-label>Password *</mat-label>
        <mat-form-field floatLabel="always" class="form-field form-field-password">
          <input
            type="{{ passwordInputType }}"
            matInput
            formControlName="password"
            maxlength="256"
            (keydown.space)="$event.preventDefault()"
            (keydown.shift.space)="$event.preventDefault()"
          />
          <mat-icon
            class="show-icon"
            [ngClass]="{ 'show-icon--showing': showingPassword }"
            (click)="showHidePassword()"
            matSuffix
          >
            {{ iconImage }}
          </mat-icon>
        </mat-form-field>
        <div class="password-validation" fxLayout="column">
          <label class="password-validation-title">Password must</label>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div [ngClass]="{ valid: validate('alphaCharStart') }" class="status-cell-icon"></div>
            <span>Start with an alpha character.</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div [ngClass]="{ valid: validate('length') }" class="status-cell-icon"></div>
            <span>Be at least 12 characters long in length.</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div [ngClass]="{ valid: validate('upperCase') }" class="status-cell-icon"></div>
            <span>Have at least one UPPERCASE.</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div [ngClass]="{ valid: validate('lowerCase') }" class="status-cell-icon"></div>
            <span>Have at least one lowercase.</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div [ngClass]="{ valid: validate('number') }" class="status-cell-icon"></div>
            <span>Have at least one number.</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div [ngClass]="{ valid: validate('symbol') }" class="status-cell-icon"></div>
            <span>Have at least one allowed special character: #$_</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div [ngClass]="{ valid: validate('notContain') }" class="status-cell-icon"></div>
            <span>Not have spaces or any special character except for: #$_</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div [ngClass]="{ valid: validate('serverName') }" class="status-cell-icon"></div>
            <span>Not contain any server name.</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div [ngClass]="{ valid: validate('userName') }" class="status-cell-icon"></div>
            <span> Not contain user ID or account name, either in whole or part. </span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div [ngClass]="{ valid: validate('no3identical') }" class="status-cell-icon"></div>
            <span> Not contain 3 or more consecutive identical characters. </span>
          </div>
        </div>
      </div>
    </form>
  </xpo-card-content>
  <xpo-card-footer>
    <xpo-button-group>
      <button mat-flat-button [disabled]="passwordForm.invalid" (click)="resetPassword()">RESET PASSWORD</button>
      <span style="margin-left: 10px">
        REMINDER: For security purposes, please close your browser after resetting your password. (Maximum password
        duration - 90 days)
      </span>
    </xpo-button-group>
  </xpo-card-footer>
</xpo-card>
