<xpo-card>
  <xpo-card-header>
    <xpo-card-title>Accounts</xpo-card-title>
  </xpo-card-header>
  <xpo-card-content>
    <xpo-ag-grid>
      <ag-grid-angular [gridOptions]="gridOptions" [rowData]="rowData" [columnDefs]="columnDefs"></ag-grid-angular>
    </xpo-ag-grid>
  </xpo-card-content>
</xpo-card>
